<template>
  <div>
    <v-container class="text-center mb-6 mt-10" fluid>
      <div class="text-start mb-5">進行状況</div>

      <!-- 相手からの評価 -->
      <v-row v-if="myEvaluation">
        <v-col cols="12" md="12">
          <primary-card
            :class="'text-center'"
            :content-height="'auto'"
          >
            <template #title>
              <span v-if="me.token === contract.client.token">
                {{ contract.challenger.nickname }} さんからの "ありがとう"
              </span>
              <span v-else>
                {{ contract.client.nickname }} さんからの "ありがとう"
              </span>
            </template>
            <template #content>
              <v-row dense>
                <v-col cols="12" md="6">
                  <v-card flat>
                    <!-- <v-card-text>
                      <base-input-rating :readonly="true" :value="myEvaluation.quality" :size="50">
                        <template #text>
                          <div class="text-start" style="width: 260px;">クエスト内容がわかりやすい</div>
                        </template>
                      </base-input-rating>
                    </v-card-text>

                    <v-divider />

                    <v-card-text>
                      <base-input-rating :readonly="true" :value="myEvaluation.communication" :size="50">
                        <template #text>
                          <div class="text-start" style="width: 260px;">コミュニケーションのとりやすさ</div>
                        </template>
                      </base-input-rating>
                    </v-card-text>

                    <v-divider />

                    <v-card-text>
                      <base-input-rating :readonly="true" :value="myEvaluation.schedule" :size="50">
                        <template #text>
                          <div class="text-start" style="width: 260px;">スケジュール感</div>
                        </template>
                      </base-input-rating>
                    </v-card-text>
                    
                    <v-divider />-->
                    <v-divider />

                    <v-card-text class="w-100">
                      <base-input-rating :readonly="true" :value="myEvaluation.thanks" :size="50">
                        <template #text>
                          <div class="default--text text-h5 font-weight-bold">ありがとう度</div>
                        </template>
                      </base-input-rating>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" md="6">
                  <v-card flat>
                    <v-card-text class="text-start">
                      <base-text-area
                        :label="'コメント'"
                        :outlined="true"
                        :readonly="true"
                        :value="myEvaluation.comment"
                      />
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </template>
          </primary-card>
        </v-col>
      </v-row>

      <!-- クエスト進行状況欄 -->
      <v-row>
        <v-col cols="12" :md="showMessageWindow ? 8 : 12">
          <v-skeleton-loader v-if="!contract" type="card" />
          <v-card
            v-else
            class="outset rounded-xl pb-10"
            :style="styles.app"
          >
            <v-card-title>
              <div style="border-left: 5px solid #fa9f00;" class="ps-3">{{ contract.quest.title }}</div>
            </v-card-title>

            <v-stepper
              v-model="currentStep"
              alt-labels
              flat
              class="app-stepper mx-10 text-center"
              :style="styles.app"
            >
              <!-- ステッパーのヘッダー -->
              <v-stepper-header
                :style="$vuetify.breakpoint.smAndUp ? '' : 'display: block;'"
              >
                <v-stepper-step
                  v-if="showInProgress"
                  :complete="contract.step > receiveQuestStep.inProgress.value"
                  :step="receiveQuestStep.inProgress.value"
                >
                  クエスト遂行中
                </v-stepper-step>

                <v-divider v-if="$vuetify.breakpoint.smAndUp" />

                <v-stepper-step
                  v-if="showDelivery"
                  :complete="contract.step > receiveQuestStep.delivery.value"
                  :step="receiveQuestStep.delivery.value"
                >
                  {{ receiveQuestStep.delivery.label }}
                </v-stepper-step>

                <v-divider v-if="$vuetify.breakpoint.smAndUp" />

                <v-stepper-step
                  v-if="showInspection"
                  :complete="contract.step > receiveQuestStep.inspection.value"
                  :step="receiveQuestStep.inspection.value"
                >
                  {{ receiveQuestStep.inspection.label }}
                </v-stepper-step>

                <v-divider v-if="$vuetify.breakpoint.smAndUp" />

                <v-stepper-step
                  v-if="showFinished"
                  :complete="contract.step > receiveQuestStep.finished.value"
                  :step="receiveQuestStep.finished.value"
                >
                  {{ receiveQuestStep.finished.label }}
                </v-stepper-step>

                <v-divider v-if="$vuetify.breakpoint.smAndUp" />

                <v-stepper-step
                  v-if="showEvaluation"
                  :complete="partnerEvaluation !== undefined && partnerEvaluation !== ''"
                  :step="receiveQuestStep.evaluation.value"
                >
                  {{ receiveQuestStep.evaluation.label }}
                </v-stepper-step>
              </v-stepper-header>

              <!-- 各ステップごとのコンテンツ -->
              <v-stepper-items :class="$vuetify.breakpoint.xsOnly ? '' : 'px-15'">
                <!-- [クエスト遂行中] -->
                <v-stepper-content
                  :step="receiveQuestStep.inProgress.value"
                >
                  <in-progress
                    :contract="contract"
                    @complete="onComplete"
                  />
                </v-stepper-content>

                <!-- [納品] -->
                <v-stepper-content
                  :step="receiveQuestStep.delivery.value"
                >
                  <delivery
                    :contract="contract"
                    @complete="onComplete"
                  />
                </v-stepper-content>

                <!-- [検品中] -->
                <v-stepper-content
                  :step="receiveQuestStep.inspection.value"
                >
                  <inspection
                    :contract="contract"
                    @complete="onComplete"
                  />
                </v-stepper-content>

                <!-- [クエスト完了] -->
                <v-stepper-content
                  :step="receiveQuestStep.finished.value"
                >
                  <finished
                    :contract="contract"
                    @complete="onComplete"
                  />
                </v-stepper-content>

                <!--
                  [評価]
                  ・undefined: 評価取得のためローディングコンポーネント表示
                  ・null     : 相手の評価を行っていないため評価入力エリア表示
                  ・上記以外  : 相手の評価を行っているので完了メッセージ表示
                -->
                <v-stepper-content
                  v-if="partnerEvaluation === undefined"
                  :step="receiveQuestStep.evaluation.value"
                >
                  <v-skeleton-loader v-if="partnerEvaluation === undefined" type="card" />
                </v-stepper-content>
                <v-stepper-content
                  v-else-if="partnerEvaluation === ''"
                  :step="receiveQuestStep.evaluation.value"
                >
                  <evaluation
                    :contract="contract"
                    :evaluation="evaluation"
                    @on-input-rating="onInputRating"
                    @on-send-challenger-rating="onSendChallengerRating"
                    @on-send-client-rating="onSendClientRating"
                  />
                </v-stepper-content>
                <v-stepper-content
                  v-else
                  :step="receiveQuestStep.evaluation.value"
                >
                  このクエストは終了しました
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>

            <show-message-window-btn
              @on-click="showMessageWindow = !showMessageWindow"
            />
          </v-card>
        </v-col>
        <v-col v-if="showMessageWindow" cols="12" md="4">
          <message-window
            :collection-id="'contracts'"
            :document-id="$route.params.contractId"
            :messages="messages"
            :sending="sending"
            :show="showMessageWindow"
            :title="me.token === contract.client.token ? contract.challenger.nickname : contract.client.nickname"
            @on-download="onDownload"
            @on-show="showMessageWindow = !showMessageWindow"
            @on-send-attachment="onSendAttachment"
            @on-send-message="onSendMessage"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import MessageWindow from '@/components/organisms/MessageWindow';
import ShowMessageWindowBtn from '@/components/organisms/ShowMessageWindowBtn';
import AppMethodsMixin from '@/mixins/AppMethodsMixin';
import AppStyleMixin from '@/mixins/AppStyleMixin';
import BaseInputRating from '@/components/atoms/BaseInputRating';
import BaseTextArea from '@/components/atoms/BaseTextArea';
import Delivery from '@/components/pages/contract/steps/Delivery';
import Evaluation from '@/components/pages/contract/steps/Evaluation';
import Finished from '@/components/pages/contract/steps/Finished';
import InProgress from '@/components/pages/contract/steps/InProgress';
import Inspection from '@/components/pages/contract/steps/Inspection';
import PrimaryCard from '@/components/organisms/PrimaryCard';
import { firestoreAuth } from '@/plugins/firebase_auth';
import crypto from '@/utils/crypto';
import { ROOT_QUEST_COLLECTION } from '@/assets/constants';
import { RECEIVE_QUEST_STEP } from '@/assets/constants';

export default {
  name: 'Detail',
  components: {
    BaseInputRating,
    BaseTextArea,
    Delivery,
    Evaluation,
    Finished,
    InProgress,
    Inspection,
    MessageWindow,
    PrimaryCard,
    ShowMessageWindowBtn
  },
  mixins: [AppMethodsMixin, AppStyleMixin],
  data() {
    return {
      contract: {
        quest: { title: '' },
        client: { token: '' },
        challenger: { token: '' }
      },
      evaluation: {
        // quality: 0.0,
        // communication: 0.0,
        // schedule: 0.0,
        thanks: 0.0
      },
      myEvaluation: null, // 自身に対する評価
      partnerEvaluation: undefined, // 相手への評価
      receiveQuestStep: RECEIVE_QUEST_STEP,
      showMessageWindow: true,
      messages: [], // 受発注者のメッセージ
      sending: false
    };
  },
  computed: {
    // ログインユーザー情報取得
    me() {
      return this.$store.getters['users/getMe'];
    },
    currentStep: {
      get() {
        if (!this.contract) {
          return undefined;
        }
        return this.contract.step;
      },
      set() {} // setterは何もしない
    },
    showInProgress() {
      return this.showStep(this.receiveQuestStep.inProgress.value);
    },
    showDelivery() {
      return this.showStep(this.receiveQuestStep.delivery.value);
    },
    showInspection() {
      return this.showStep(this.receiveQuestStep.inspection.value);
    },
    showFinished() {
      return this.showStep(this.receiveQuestStep.finished.value);
    },
    showEvaluation() {
      return this.showStep(this.receiveQuestStep.evaluation.value);
    }
  },
  methods: {
    async onComplete() {
      if (confirm('完了しますか?')) {
        await this.$store.dispatch('receiveQuests/complete', {
          contractId: this.$route.params.contractId,
          step: this.contract.step
        });
        this.contract.step += 100;
      }
    },
    /** 評価入力（星クリック） */
    onInputRating(e) {
      this.evaluation = { ...this.evaluation, ...e };
    },
    async onSendChallengerRating() {
      if (confirm('"ありがとう"を送信しますか?')) {
        this.partnerEvaluation = await this.$store.dispatch('receiveQuests/sendChallengerRating', {
          contractId: this.$route.params.contractId,
          params: this.evaluation
        });
      }
    },
    async onSendClientRating() {
      if (confirm('"ありがとう"を送信しますか?')) {
        this.partnerEvaluation = await this.$store.dispatch('receiveQuests/sendClientRating', {
          contractId: this.$route.params.contractId,
          params: this.evaluation
        });
      }
    },
    showStep(step) {
      if (!this.contract) {
        return true;
      }
      // 画面幅が極端に小さい場合
      if (this.$vuetify.breakpoint.xsOnly) {
        return this.contract.step === step;
      }
      return true;
    },
    /** ファイル送信 */
    async onSendAttachment(selectedFile) {
      if (confirm('ファイルを送信しますか?')) {
        // ファイルアップロード処理
        let formData = new FormData();
        formData.append('file', selectedFile);
        await this.$store.dispatch(
          'receiveQuests/sendAttachment',
          {
            contractId: this.$route.params.contractId,
            data: formData
          }
        );
      }
    },
    /** メッセージ送信 */
    async onSendMessage(message) {
      this.sending = true;

      const formData = new FormData();
      formData.append('message', crypto.encrypt(message, this.contract.cryptoKey));

      try {
        await this.$store.dispatch(
          'receiveQuests/sendMessage',
          { contractId: this.$route.params.contractId, data: formData });

      } finally {
        this.sending = false;
      }
    },
    /** 添付ファイルダウンロード */
    async onDownload(filename, downloadPath) {
      if (confirm(`「${filename}」をダウンロードしますか?`)) {
        const response = await this.$store.dispatch(
          'receiveQuests/downloadAttachment',
          { contractId: this.$route.params.contractId, downloadPath: downloadPath });
        const blob = new Blob([response.data]);
        const link = document.createElement('a');
        const _URL = window.URL || window.webkitURL;
        link.download = filename;
        link.href = _URL.createObjectURL(blob);
        try {
          link.click();
        } finally {
          _URL.revokeObjectURL(link.href);
        }
      }
    },
    async getMessages() {
      this.messages = [];
      const messageRefs = firestoreAuth
        .collection(`${ROOT_QUEST_COLLECTION}contracts/${this.$route.params.contractId}/messages`)
        .orderBy('timestamp');

      messageRefs.onSnapshot(snapshot => {
        snapshot.docChanges().forEach(change => {
          let data = change.doc.data();

          // 暗号化されているメッセージを複合
          data.message = crypto.decrypt(data.message, this.contract.cryptoKey);

          // 相手からの未読メッセージは、そのドキュメントパスを変数に追加していく（後で既読マークを付けるため）
          // 相手からの未読メッセージは既読にする
          if (this.showMessageWindow) {
            if (this.me.token && !data.isRead && data.token !== this.me.token) {
              change.doc.ref.update({ isRead: true });
              return;
            }
          }

          const index = this.messages.map(message => { return message.messageId; }).indexOf(data.messageId);
          if (index === -1) {
            this.messages.push(data);
          } else {
            this.$set(this.messages, index, data);
          }
        });
      });
    }
  },
  mounted() {
    this.showLoadingOverlay(async () => {
      this.contract = await this.$store.dispatch('receiveQuests/retrieve', this.$route.params.contractId);

      // クエリパラメータにshowMessageがある場合はメッセージUIを初期表示
      if (this.$route.query.showMessage) {
        this.showMessageWindow = true;
      }

      // ユーザーが受注者の場合
      // ・発注者からの"ありがとう"を取得
      // ・ユーザーが行った発注者評価を取得
      if (this.me.token === this.contract.challenger.token) {
        this.$store.dispatch('receiveQuests/getChallengerRating', { contractId: this.$route.params.contractId, challengerId: this.me.token })
          .then(
            responseData => {
              if (responseData) {
                this.myEvaluation = responseData;
              }
            }
          );
        this.$store.dispatch('receiveQuests/getClientRating', { contractId: this.$route.params.contractId, clientId: this.contract.client.token })
          .then(
            responseData => {
              this.partnerEvaluation = responseData;
            }
          );
      }

      // ユーザーが発注者の場合
      // ・受注者からの"ありがとう"を取得
      // ・ユーザーが行った受注者評価を取得
      if (this.me.token === this.contract.client.token) {
        this.$store.dispatch('receiveQuests/getClientRating', { contractId: this.$route.params.contractId, clientId: this.me.token })
          .then(
            responseData => {
              if (responseData) {
                this.myEvaluation = responseData;
              }
            }
          );
        this.$store.dispatch('receiveQuests/getChallengerRating', { contractId: this.$route.params.contractId, challengerId: this.contract.challenger.token })
          .then(
            responseData => {
              this.partnerEvaluation = responseData;
            }
          );
      }

      // メッセージ取得
      this.getMessages();
    });
  }
};
</script>

<style lang="scss" scoped>
// ステッパーのヘッダーとコンテンツの間の罫線(?)を非表示
.v-stepper__header {
  box-shadow: none;
}

// ステッパーのヘッダーとコンテンツの間隔を狭める
.v-stepper__step {
  padding-bottom: 0;
}

// ステッパーの番号を非表示にする
.app-stepper::v-deep .v-stepper__step__step {
  font-size: 0
}

// ステッパーのアクティブヘッダー文字色
.app-stepper::v-deep .v-stepper__step--active .v-stepper__label {
  color: #443f35 !important;
}

.input-message::v-deep {
  textarea {
    max-height: 15rem;
    overflow: auto;
  }
}
</style>
