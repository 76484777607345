<template>
  <v-fab-transition>
    <v-btn
      color="primary"
      fab
      large
      absolute
      bottom
      right
      @click="onClick"
    >
      <v-icon>mdi-chat-processing</v-icon>
    </v-btn>
  </v-fab-transition>
</template>

<script>
export default {
  name: 'ShowMessageWindowBtn',
  methods: {
    onClick(e) {
      this.$emit('on-click', e);
    }
  }
};
</script>
