<template>
  <v-card
    class="outset rounded-xl"
    style="display: flex; flex-direction: column; height: 600px;"
    :style="styles.app"
    flat
  >
    <!-- 相手の名前 -->
    <v-card-title class="text-h5 font-weight-bold d-flex align-center justify-center">
      {{ title }}
      <v-btn
        class="font-weight-bold"
        color="primary"
        style="position: absolute; right: 5px;"
        plain
        @click="$emit('on-show')"
      >
        閉じる
      </v-btn>
    </v-card-title>
    <v-divider />

    <!-- メッセージ表示エリア -->
    <v-card-text
      class="line__contents"
      style="flex: 9; overflow-y: auto; height: 100%;"
      ref="messageArea"
    >
      <template v-for="(item, i) in messages">
        <div
          v-if="item.token === me.token"
          class="line__right"
          :key="i"
        >
          <div class="line__right-text text-start">
            <!-- メッセージ -->
            <div class="text" v-html="nl2br(item.message)" />

            <!-- ダウンロードアイコン および 既読のチェックマーク および 日付 -->
            <span class="date">
              <span v-if="item.isRead">✔︎<br /></span>
              <v-icon v-if="item.downloadPath" size="20" @click="onDownload(item.message, item.downloadPath)">mdi-download</v-icon>
              {{ dateFormat(item.timestamp.toDate(), 'M/D') }}
            </span>
          </div>
        </div>
        <div
          v-else
          class="line__left"
          :key="i"
        >
          <div class="line__left-text text-start">
            <!-- メッセージ -->
            <div class="text" v-html="nl2br(item.message)" />

            <!-- 日付 および ダウンロードアイコン -->
            <span class="date">
              {{ dateFormat(item.timestamp.toDate(), 'M/D') }}
              <v-icon v-if="item.downloadPath" size="20" @click="onDownload(item.message, item.downloadPath)">mdi-download</v-icon>
            </span>
          </div>
        </div>
      </template>

      <v-snackbar
        :timeout="-1"
        :value="showErrorMessage"
        absolute
        color="error"
        top
      >
        {{ errorMessage }}
      </v-snackbar>
    </v-card-text>
    <v-divider />

    <!-- メッセージ入力エリア -->
    <v-card-actions class="px-2 mb-n7">
      <send-message-field
        :class-name="'input-message'"
        :sending="sending"
        @on-send-attachment="onSendAttachment"
        @on-send-message="onSendMessage"
      />
    </v-card-actions>
  </v-card>
</template>

<script>
import AppMethodsMixin from '@/mixins/AppMethodsMixin';
import AppStyleMixin from '@/mixins/AppStyleMixin';
import SendMessageField from '@/components/organisms/SendMessageField';
import messages from '@/assets/messages';

export default {
  name: 'MessageWindow',
  components: { SendMessageField },
  mixins: [AppMethodsMixin, AppStyleMixin],
  props: {
    messages: {
      type: Array,
      default: () => [],
      require: true
    },
    sending: {
      type: Boolean,
      default: false
    },
    show: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      errorMessage: '',
      showErrorMessage: false
    };
  },
  computed: {
    _show: {
      get() {
        return this.show;
      },
      set(v) {
        this.$emit('on-show', v);
      }
    },
    me() {
      return this.$store.getters['users/getMe'];
    }
  },
  methods: {
    /** ファイル送信 */
    onSendAttachment(selectedFile) {
      this.showErrorMessage = false;

      // ファイル未選択の場合は何もしない
      if (!selectedFile) {
        return;
      }

      // アップロードファイルのサイズが上限を超えている場合はエラーメッセージを5秒間表示
      if (selectedFile.size > process.env.VUE_APP_FILE_UPLOAD_MAX_MEMORY_SIZE) {
        this.onShowErrorMessage(messages.E_UPLOAD_MAX_MEMORY_SIZE(`${Math.floor(process.env.VUE_APP_FILE_UPLOAD_MAX_MEMORY_SIZE / (1024 * 1024))}MB`));
        return;
      }

      // ファイルサイズが上限内の場合はアップロード処理に進む
      this.$emit('on-send-attachment', selectedFile);
    },
    /** メッセージ送信 */
    onSendMessage(message) {
      this.$emit('on-send-message', message);
    },
    onDownload(filename, downloadPath) {
      this.$emit('on-download', filename, downloadPath);
    },
    onShowErrorMessage(errorMessage) {
      this.errorMessage = errorMessage;
      this.showErrorMessage = true;
        setTimeout(function() {
          this.showErrorMessage = false;
        }.bind(this), 5000);
    }
  },
  mounted() {
    if (this._show) {
      this.$nextTick(() => {
        let messageArea = this.$refs['messageArea'];
        setTimeout(() => {
          messageArea.scrollTop = messageArea.scrollHeight;
        }, 100);
      });
    }
  },
  watch: {
    _show() {
      this.$nextTick(() => {
        let messageArea = this.$refs['messageArea'];
        setTimeout(() => {
          messageArea.scrollTop = messageArea.scrollHeight;
        }, 100);
      });
    }
  }
};
</script>

<style lang="scss" scoped>
/* 会話部分 */
.line__contents {
  // padding: 10px;
  // overflow: hidden;
  line-height: 135%;

  &.scroll {
    height: 500px;
    overflow-y: scroll;
  }

  // /* 相手の会話 */
  .line__left {
    position: relative;
    display: block;
    margin: 5px 0;
    max-width: 75%;
    float: left;
    margin-left: 15px;
    clear: both;

    /* コメントエリア */
    .text {
      padding: 10px;
      border-radius: 20px;
      background-color: #666666;
      color: white;

      /* 吹き出し */
      &::after {
        content: '';
        position: absolute;
        display: block;
        width: 0;
        height: 0;
        left: -10px;
        top: 10px;
        border-right: 20px solid #666666;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
      }
    }
    /* 既読エリア */
    .date {
      content: '';
      position: absolute;
      display: block;
      width: 100px;
      text-align: left;
      right: -110px;
      bottom: 0px;
      font-size: 80%;
    }
  }

  /* 自分の会話 */
  .line__right {
    position: relative;
    display: block;
    margin: 5px 0;
    max-width: 75%;
    float: right;
    margin-right: 15px;
    clear: both;

    /* コメントエリア */
    .text {
      padding: 10px;
      border-radius: 20px;
      background-color: #fa9f00;
      color: #ffffff;
      // margin-left: 80px;

      /* 吹き出し */
      &::after {
        content: '';
        position: absolute;
        display: block;
        width: 0;
        height: 0;
        right: -10px;
        top: 10px;
        border-left: 20px solid #fa9f00;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
      }
    }
    /* 既読エリア */
    .date {
      content: '';
      position: absolute;
      display: block;
      width: 100px;
      text-align: right;
      left: -110px;
      bottom: 0px;
      font-size: 80%;
    }
  }
}

.input-message ::v-deep textarea {
  max-height: 300px;
  overflow-y: scroll;
}
</style>
