<template>
  <div>
    <v-card
      style="border: 3px double #bdbdbd;"
      flat
    >
      <v-card-text class="text-start">
        <!-- 受注者に見せるテキスト -->
        <template v-if="me.token === contract.challenger.token">
          検品が完了しました。<br />
          現在{{ contract.client.nickname }}さんのクエスト完了を待っています。<br />
          クエストが完了したら{{ contract.client.nickname }}さんの評価を入力しましょう。
        </template>

        <!-- 発注者に見せるテキスト -->
        <template v-if="me.token === contract.client.token">
          検品が完了しました。<br />
          クエストを完了にして、{{ contract.challenger.nickname }}さんの評価を入力しましょう。
        </template>
      </v-card-text>

      <!-- ボタンは発注者にだけ表示 -->
      <base-button
        v-if="me.token === contract.client.token"
        :class-name="'my-5'"
        :color="'primary'"
        :outset="false"
        @click="onClick"
      >
        <template #text>
          クエストを完了にする
        </template>
      </base-button>
    </v-card>
  </div>
</template>

<script>
import BaseButton from '@/components/atoms/BaseButton';

export default {
  components: { BaseButton },
  props: {
    contract: {
      type: Object,
      default: () => {},
      require: true
    }
  },
  computed: {
    // ログインユーザー情報取得
    me() {
      return this.$store.getters['users/getMe'];
    }
  },
  methods: {
    onClick() {
      this.$emit('complete');
    }
  }
};
</script>
