<template>
  <v-textarea
    :auto-grow="autoGrow"
    :background-color="'#fff'"
    :class="className"
    :counter="counter"
    :disabled="disabled"
    :hint="hint"
    :label="label"
    :name="name"
    :outlined="outlined"
    :persistent-hint="persistentHint"
    :persistent-placeholder="persistentPlaceholder"
    :placeholder="placeholder"
    :readonly="readonly"
    :rules="rules"
    :value="value"
    @input="onInput"
  />
</template>

<script>
export default {
  name: 'BaseTextArea',
  props: {
    autoGrow: {
      type: Boolean,
      default: true
    },
    className: {
      type: String,
      default: ''
    },
    counter: {
      type: [Boolean, Number, String],
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    hint: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    outlined: {
      type: Boolean,
      default: true
    },
    persistentHint: {
      type: Boolean,
      default: false
    },
    persistentPlaceholder: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    readonly: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Array,
      default: () => []
    },
    value: { 
      type: String,
      default: ''
    }
  },

  methods: {
    onInput(e) {
      this.$emit('input', e);
    }
  }
};
</script>
