<template>
  <div>
    <v-card
      style="border: 3px double #bdbdbd;"
      flat
    >
      <v-card-text class="text-start">
        <!-- 受注者に見せるテキスト -->
        <template v-if="me.token === contract.challenger.token">
          {{ contract.client.nickname }}さんに"ありがとう"を送りましょう。<br />
          <span class="font-weight-bold">"ありがとう"は{{ contract.client.nickname }}さんに公開されます。</span>
        </template>

        <!-- 発注者に見せるテキスト -->
        <template v-if="me.token === contract.client.token">
          {{ contract.challenger.nickname }}さんに"ありがとう"を送りましょう。<br />
          <span class="font-weight-bold">"ありがとう"は{{ contract.challenger.nickname }}さんに公開されます。</span>
        </template>
      </v-card-text>

      <v-divider class="mx-3" />

      <!-- <v-card-actions>
        <base-input-rating :value="evaluation.quality" @input="onInput({ quality: $event })">
          <template #text>
            <div
              v-if="me.token === contract.challenger.token"
              class="text-start"
              style="width: 260px;"
            >
              クエスト内容がわかりやすい
            </div>
            <div
              v-if="me.token === contract.client.token"
              class="text-start"
              style="width: 260px;"
            >
              納品物のクオリティ
            </div>
          </template>
        </base-input-rating>
      </v-card-actions>
      <v-card-actions>
        <base-input-rating :value="evaluation.communication" @input="onInput({ communication: $event })">
          <template #text>
            <div class="text-start" style="width: 260px;">コミュニケーションのとりやすさ</div>
          </template>
        </base-input-rating>
      </v-card-actions>
      <v-card-actions>
        <base-input-rating :value="evaluation.schedule" @input="onInput({ schedule: $event })">
          <template #text>
            <div class="text-start" style="width: 260px;">スケジュール感</div>
          </template>
        </base-input-rating>
      </v-card-actions> -->
      <v-card-text class="w-100">
        <base-input-rating :value="evaluation.thanks" @input="onInput({ thanks: $event })" :size="50">
          <template #text>
            <div class="default--text text-h5 font-weight-bold">ありがとう度</div>
          </template>
        </base-input-rating>
      </v-card-text>

      <v-card-text class="text-start">
        <base-text-area
          :counter="500"
          :label="'コメント'"
          :outlined="true"
          :rules="[maxLength(500)]"
          :value="evaluation.comment"
          @input="onInput({ comment: $event })"
        />
      </v-card-text>

      <v-divider class="mx-3" />

      <base-button
        :class-name="'my-5'"
        :color="'primary'"
        :outset="false"
        @click="onClick"
      >
        <template #text>
          "ありがとう"を送る
        </template>
      </base-button>
    </v-card>
  </div>
</template>

<script>
import BaseButton from '@/components/atoms/BaseButton';
import BaseInputRating from '@/components/atoms/BaseInputRating';
import BaseTextArea from '@/components/atoms/BaseTextArea';
import FormRulesMixin from '@/mixins/FormRulesMixin';

export default {
  components: { BaseButton, BaseInputRating, BaseTextArea },
  mixins: [FormRulesMixin],
  props: {
    contract: {
      type: Object,
      default: () => {},
      require: true
    },
    evaluation: {
      type: Object,
      default: () => {
        return {
          quality: 0.0,
          communication: 0.0,
          schedule: 0.0,
          comment: ''
        };
      }
    }
  },
  computed: {
    // ログインユーザー情報取得
    me() {
      return this.$store.getters['users/getMe'];
    }
  },
  methods: {
    onClick() {
      // ユーザーが受注者の場合は、発注者評価登録を実行
      if (this.me.token === this.contract.challenger.token) {
        this.$emit('on-send-client-rating');
      }

      // ユーザーが発注者の場合は、受注者評価登録を実行
      if (this.me.token === this.contract.client.token) {
        this.$emit('on-send-challenger-rating');
      }
    },
    onInput(e) {
      this.$emit('on-input-rating', e);
    }
  }
};
</script>
