<template>
  <div>
    <div class="d-flex align-center justify-center">
      <slot name="text" />
      <span class="font-weight-bold pt-1">&emsp;({{ Number(value).toFixed(1) }})</span>
    </div>
      <v-rating
        :background-color="backgroundColor"
        :color="color"
        :dense="dense"
        :half-increments="halfIncrements"
        :hover="hover"
        :length="length"
        :readonly="readonly"
        :size="size"
        :value="Number(value)"
        @input="onInput"
      />
  </div>
</template>

<script>
export default {
  name: 'BaseInputRating',
  props: {
    backgroundColor: {
      type: String,
      default: 'yellow accent-4'
    },
    color: {
      type: String,
      default: 'yellow accent-4'
    },
    dense: {
      type: Boolean,
      default: true
    },
    halfIncrements: {
      type: Boolean,
      default: true
    },
    hover: {
      type: Boolean,
      default: true
    },
    length: {
      type: Number,
      default: 5
    },
    readonly: {
      type: Boolean,
      default: false
    },
    size: {
      type: Number,
      default: 32
    },
    value: {
      type: [Number, String],
      default: 0
    }
  },
  methods: {
    onInput(e) {
      this.$emit('input', e);
    }
  }
};
</script>
