<template>
  <v-textarea
    v-if="!sending"
    v-model="message"
    :auto-grow="autoGrow"
    :background-color="'#fff'"
    :class="className"
    :counter="counter"
    :dense="dense"
    :disabled="disabled"
    :hint="hint"
    :label="label"
    :name="name"
    :outlined="outlined"
    :persistent-hint="persistentHint"
    :persistent-placeholder="persistentPlaceholder"
    :placeholder="placeholder"
    :readonly="readonly"
    :rows="rows"
    :rules="rules"
    :value="value"
  >
    <template #prepend>
      <v-file-input
        class="mt-n2"
        label="ファイル送信"
        filled
        hide-input
        prepend-icon="mdi-paperclip"
        accept="image/*"
        @change="onSendAttachment"
      />
    </template>
    <template #append-outer>
      <v-icon v-if="message.trim()" @click="onSendMessage" :color="'primary'">mdi-send</v-icon>
      <v-icon v-else color="'default'">mdi-send</v-icon>
    </template>
  </v-textarea>
  <span v-else>
    <div class="animate seven font-weight-bold pt-1 ps-5" style="height: 66px;">
      <span>メ</span><span>ッ</span><span>セ</span><span>ー</span><span>ジ</span>
      <span>を</span><span>送</span><span>信</span><span>し</span><span>て</span><span>い</span><span>ま</span><span>す</span>
      <span>・</span><span>・</span><span>・</span>
    </div>
  </span>
</template>

<script>
export default {
  name: 'SendMessageField',
  props: {
    autoGrow: {
      type: Boolean,
      default: true
    },
    className: {
      type: String,
      default: ''
    },
    counter: {
      type: [Boolean, Number, String],
      default: false
    },
    dense: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    hint: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    outlined: {
      type: Boolean,
      default: true
    },
    persistentHint: {
      type: Boolean,
      default: false
    },
    persistentPlaceholder: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    readonly: {
      type: Boolean,
      default: false
    },
    rows: {
      type: Number,
      default: 1
    },
    rules: {
      type: Array,
      default: () => []
    },
    sending: {
      type: Boolean,
      default: false
    },
    value: { 
      type: String,
      default: ''
    }
  },
  data() {
    return {
      message: ''
    };
  },
  methods: {
    /** ファイル送信 */
    onSendAttachment(selectedFile) {
      this.$emit('on-send-attachment', selectedFile);
    },
    /** メッセージ送信 */
    onSendMessage() {
      this.$emit('on-send-message', this.message);
      this.message = '';
    }
  }
};
</script>

<style lang="scss" scoped>
.animate span {
  display: inline-block;
}

.animate span:nth-of-type(2) {
  animation-delay: .05s;
}
.animate span:nth-of-type(3) {
  animation-delay: .1s;
}
.animate span:nth-of-type(4) {
  animation-delay: .15s;
}
.animate span:nth-of-type(5) {
  animation-delay: .2s;
}
.animate span:nth-of-type(6) {
  animation-delay: .25s;
}
.animate span:nth-of-type(7) {
  animation-delay: .3s;
}
.animate span:nth-of-type(8) {
  animation-delay: .35s;
}
.animate span:nth-of-type(9) {
  animation-delay: .4s;
}
.animate span:nth-of-type(10) {
  animation-delay: .45s;
}
.animate span:nth-of-type(11) {
  animation-delay: .5s;
}
.animate span:nth-of-type(12) {
  animation-delay: .55s;
}
.animate span:nth-of-type(13) {
  animation-delay: .6s;
}
.animate span:nth-of-type(14) {
  animation-delay: .65s;
}
.animate span:nth-of-type(15) {
  animation-delay: .7s;
}
.animate span:nth-of-type(16) {
  animation-delay: .75s;
}

.seven span {
  color: #FA9F00;
  opacity: 1;
  animation: leftRight 0.8s infinite;
}

@keyframes leftRight {
  40% {
    transform: translateY(4px) scale(1);
    opacity: 1;
    color: #FA9F00;
  }

  60% {
    color: #FA9F00;
  }

  80% {
    transform: translateY(1px) scale(1);
    opacity: 1;
  }

  100% {
    transform: translateY(0) scale(1);
    opacity: 1;
  }
}
</style>
